/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import phoneUtils from '@exuus/rwanda-phone-utils';
import Toolbar from 'components/common/Toolbar';
import ToolbarFooter from 'components/common/Toolbar/ToolbarFooter';
import Message from 'components/common/Message';
import { isOrganizationAdmin } from 'helpers/checkRoles';
import Thumbnail from 'components/common/Thumbnail';
import './index.scss';
import ExpandableRow from 'components/common/ExpandableRow';
import agentFormatter from 'helpers/shapeFormatter/allAgents';
import membersFormatter from 'helpers/shapeFormatter/allMembers';
import getUserRoles from 'helpers/getUserRoles';

const UsersList = ({
  meta,
  handleGetUsers,
  loading,
  error,
  message,
  users,
  noToolbar,
  onSearch,
  onPaginate,
  allUsers,
  type,
}) => {
  const { currentOrgId, currentUserType, orgUsers } = getUserRoles();

  return (
    <>
      {!noToolbar && (
        <Toolbar
          header={{
            title: 'Organization Users',
            rightComponent: (
              <>
                <ToolbarFooter
                  onPaginate={onPaginate}
                  paginationMeta={meta}
                  onSearch={onSearch}
                  exportCSVData={users()}
                  exportCSVFileName="users"
                />

                {currentUserType &&
                  isOrganizationAdmin({
                    currentOrgId,
                    orgUsers,
                  }) && (
                    <Button
                      icon
                      labelPosition="right"
                      data-cy="create-user-link"
                      id="new-user"
                      as={Link}
                      to="/create-user"
                    >
                      Add user
                      <Icon name="plus" />
                    </Button>
                  )}
              </>
            ),
          }}
        />
      )}

      <section className="organization-users scroll-wrapper">
        {loading && <Loader active inline="centered" />}
        {!loading && error && (
          <Message
            message={error.message}
            color="red"
            action={{
              onClick: () => handleGetUsers(),
            }}
          />
        )}
        {!loading && message && (
          <Message
            message={message}
            icon="info circle"
            action={{
              onClick: () => handleGetUsers(),
            }}
          />
        )}
        {users() &&
          users()
            .filter(user => user?.status !== 'inactive')
            .map(user => (
              <div
                className={`organization-user ${
                  user?.status === 'inactive' || user?.is_deactivated
                    ? `deactivated-user`
                    : ''
                }`}
              >
                <Link
                  to={
                    (isOrganizationAdmin({
                      currentOrgId,
                      orgUsers,
                    }) ||
                      allUsers) && {
                      pathname: !allUsers
                        ? `/users/${user?.id}`
                        : `/user/${user?.id}`,
                      state: {
                        id: user?.id,
                        firstName: user?.first_name,
                        lastName: user?.last_name,
                      },
                    }
                  }
                >
                  <Thumbnail
                    name={user?.first_name}
                    secondName={user?.last_name}
                  />
                </Link>

                <div className="details">
                  <div className="info">
                    <Link
                      to={
                        (isOrganizationAdmin({
                          currentOrgId,
                          orgUsers,
                        }) ||
                          allUsers) && {
                          pathname: !allUsers
                            ? `/users/${user?.id}`
                            : `/user/${user?.id}`,
                          state: {
                            id: user?.id,
                            firstName: user?.first_name,
                            lastName: user?.last_name,
                          },
                        }
                      }
                    >
                      <div className="name">{`${user?.first_name ||
                        'N/A'} ${user?.last_name || ' '}`}</div>
                    </Link>

                    {type === 'agent' ? (
                      <ExpandableRow
                        data={agentFormatter(user)}
                        redirectUrl={`/users/${user?.id}`}
                      />
                    ) : type === 'member' ? (
                      <ExpandableRow
                        data={membersFormatter(user)}
                        redirectUrl={`/users/${user?.id}`}
                      />
                    ) : (
                      <div className="contact">
                        <span className="user_id">
                          <strong>ID: </strong>
                          {user?.id ?? '-'}
                        </span>
                        {user?.phone_number && (
                          <span className="phone_number">
                            <Icon name="phone" />{' '}
                            {phoneUtils(user?.phone_number).format(
                              'space',
                            )}
                          </span>
                        )}

                        <span className="email">
                          <Icon name="at" />{' '}
                          {user?.work_email || user?.email || 'N/A'}
                        </span>

                        <span className="created_at">
                          <b>Joined on:</b>{' '}
                          {moment(user?.details?.created_at).format(
                            'Do MMMM YYYY',
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="right-info">
                    <div className="roles">
                      {user?.userType &&
                        !user?.isFinanceRwandaAdmin &&
                        !user?.isSupportAdmin &&
                        isOrganizationAdmin({
                          userType: user?.userType,
                        }) && <div className="role">Admin</div>}

                      {user?.isFinanceRwandaAdmin && (
                        <div className="role badge__blue">
                          Wallet Admin
                        </div>
                      )}

                      {user?.isSupportAdmin && (
                        <div className="role badge__blue">
                          Support Admin
                        </div>
                      )}
                      {user?.isTechnicalLead && (
                        <div className="role badge__blue">
                          Tech Admin
                        </div>
                      )}

                      {user?.isCommunicationsAdmin && (
                        <div className="role badge__blue">
                          Communication Admin
                        </div>
                      )}

                      {user?.is_blocked && (
                        <div className="role badge__blocked">
                          Blocked
                        </div>
                      )}

                      {user?.status !== 'active' && (
                        <div
                          className={`role badge__${user?.status}`}
                        >
                          {user?.status}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </section>
    </>
  );
};

UsersList.defaultProps = {
  noToolbar: false,
  message: null,
  meta: {
    page: 1,
    pages: 1,
  },
  allUsers: false,
  error: '',
  onPaginate: () => '',
  onSearch: () => '',
  type: '',
};

UsersList.propTypes = {
  meta: PropTypes.shape({
    page: PropTypes.number,
    pages: PropTypes.number,
  }),
  handleGetUsers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  message: PropTypes.oneOfType([PropTypes.string]),
  users: PropTypes.func.isRequired,
  noToolbar: PropTypes.bool,
  onSearch: PropTypes.func,
  onPaginate: PropTypes.func,
  allUsers: PropTypes.bool,
  type: PropTypes.string,
};

export default UsersList;
