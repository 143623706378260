/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import {
  Grid,
  Message,
  Button,
  Dropdown,
  Icon,
  Popup,
  Header,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import getProfileOverview from 'redux/actions/organizations/profiles/profileOverview';
import getCurrentOrg from 'helpers/getCurrentOrg';
import { canAccess } from 'helpers/permissions';
import Toolbar from 'components/common/Toolbar';
import isParentOrganization from 'helpers/isParentOrganization';
import Loader from 'components/common/PageLoader';
import ProfileOverviewCharts from './ProfileOverviewCharts';
import ProfileGenders from './ProfileGenders';
import ProfileDetails from './ProfileDetails';
import 'containers/Organizations/SingleOrganization/Groups/index.scss';
import StatCharts from './StatsCharts';
import AverageCreditScore from './AverageCreditScore';

const Overview = ({ history }) => {
  const params = useParams();
  const {
    organizationId,
    profileId: theProfileId,
    tabOption,
  } = params;
  const profileId = theProfileId || tabOption;

  const { pathname } = useLocation();

  const currentYear = useRef();

  const { data, loading, error } = useSelector(
    ({ profiles: { profileOverview } }) => profileOverview,
  );

  const [pathnameError, setPathnameError] = useState('');

  const dispatch = useDispatch();

  const currentUserOrganization = getCurrentOrg();
  const iscurrentOrgParent = isParentOrganization();

  const getOverview = (organizationId, profileId) =>
    getProfileOverview(organizationId, profileId)(dispatch);

  useEffect(() => {
    getOverview(
      organizationId || currentUserOrganization?.id,
      profileId,
    );
  }, [dispatch, organizationId, profileId]);

  useEffect(() => {
    if (
      pathname === `/profiles/${profileId}/overview` &&
      !canAccess('profiles', null, {
        currentUserOrganization,
      })
    ) {
      setPathnameError('You are not authorized to view this page');
    } else if (
      pathname ===
        `/organizations/${organizationId}/profiles/${profileId}/overview` &&
      !iscurrentOrgParent
    ) {
      setPathnameError('You are not authorized to view this page');
    }
  }, [currentUserOrganization, iscurrentOrgParent]);

  const isActionApplicable = action => {
    switch (action) {
      case 'remove':
      case 'delete':
      default:
        return null;
    }
  };

  const changeProfileSection = profileSection => {
    history.push(
      !pathname.includes('/organizations/')
        ? `/profiles/${profileId}/${profileSection}`
        : `/organizations/${organizationId ||
            currentUserOrganization?.id}/profiles/${profileId}/${profileSection}`,
    );
  };

  return (
    <>
      <Toolbar
        header={{
          title: data?.profile?.name,
          navigation: true,
          breadcrumbTitle: 'breadcrumb-title',
          breadcrumbParent: data?.profile?.name && '',
          rightComponent: pathname.includes('/organizations/') &&
            !loading && (
              <>
                <Dropdown
                  className="setting-dropdown"
                  icon={false}
                  floating
                  labeled
                  trigger={
                    <Button icon primary labelPosition="right">
                      <span>
                        <Icon name="setting" />
                      </span>
                      More
                      <Icon name="triangle down" />
                    </Button>
                  }
                >
                  <Dropdown.Menu className="right">
                    <Popup
                      content="Sorry, You can delete only a member with 0 Rwf saving Balance"
                      disabled={isActionApplicable('delete')}
                      trigger={
                        <>
                          <Dropdown.Item
                            onClick={() =>
                              changeProfileSection('groups')
                            }
                          >
                            <Header as="h4">Groups</Header>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              changeProfileSection('requests')
                            }
                          >
                            <Header as="h4">Requests</Header>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() =>
                              changeProfileSection('settings')
                            }
                            disabled={
                              data?.profile?.profile_type ===
                              'default'
                            }
                          >
                            <Header as="h4">Profile Settings</Header>
                          </Dropdown.Item>
                        </>
                      }
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ),
        }}
      />

      <div className="group-overview scroll-wrapperr profile-overview">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message negative>{error.message || pathnameError}</Message>
        ) : (
          <Grid>
            <Grid.Row columns="3">
              <Grid.Column width="8">
                <ProfileOverviewCharts data={data} />
              </Grid.Column>
              <Grid.Column width="5" rows={2} gap={2}>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <ProfileDetails data={data} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Grid.Column>
                      <AverageCreditScore
                        data={data?.average_credit_score ?? []}
                      />
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width="3">
                <ProfileGenders data={data} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="1">
              <Grid.Column width="16">
                <StatCharts data={data} year={currentYear.current} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    </>
  );
};

Overview.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
};

export default Overview;
