/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Label, Icon, Popup, Divider } from 'semantic-ui-react';
import capitalize, { capitalizeEachWords } from 'utils/capitalize';
import formatPhone from 'helpers/formatPhone';
import currencyFormat from 'utils/currencyFormat';
import formatDate from 'utils/formatDate';

export const getStatusColor = status => {
  switch (status) {
    case 'pending':
      return 'yellow';

    case 'approved':
      return 'green';

    case 'accepted':
      return 'green';

    case 'submitted':
      return 'green';

    case 'cleared':
      return 'green';

    case 'amended':
      return 'blue';

    default:
      break;
  }
};

const creditScoreExplainer = [
  {
    range: '300 - 559',
    color: '#DB2829',
    description: 'Very Bad',
  },
  {
    range: '560 - 649',
    color: '#FF5630',
    description: 'Bad',
  },
  {
    range: '650 - 699',
    color: '#D77501',
    description: 'Fair',
  },
  {
    range: '700 - 749',
    color: '#00E485',
    description: 'Good',
  },
  {
    range: '750 - 850',
    color: '#029357',
    description: 'Excellent',
  },
];

const getCreditScoreColor = creditScore => {
  if (creditScore >= 300 && creditScore <= 559) {
    return '#DB2829';
  }
  if (creditScore >= 560 && creditScore <= 649) {
    return '#FF5630';
  }
  if (creditScore >= 650 && creditScore <= 699) {
    return '#D77501';
  }
  if (creditScore >= 700 && creditScore <= 749) {
    return '#00E485';
  }
  if (creditScore >= 750 && creditScore <= 850) {
    return '#029357';
  }
  return 'grey';
};

const RequestPopupContent = ({ request }) => {
  return (
    <div className="loan-request-info-popup">
      <div className="loan-request-info-popup__header">
        <b className="">
          {capitalizeEachWords(request?.type?.replace(/_/g, ' '))}
        </b>

        <span
          className={`loan-request-info-popup__${
            request.is_modified && request.status === 'pending'
              ? 'amended'
              : request?.status === 'submitted'
              ? 'pending'
              : request?.status
          }`}
        >
          {request.is_modified && request.status === 'pending'
            ? 'Amended'
            : request?.status === 'submitted'
            ? 'Pending'
            : capitalizeEachWords(request?.status)}
        </span>
      </div>
      <div className="loan-request-info-popup__body">
        <div className="loan-request-info-popup__body__requester-info">
          <div className="loan-request-info-popup__body__requester-info__title">
            <span>Admin approvals</span>
          </div>

          {request?.admins_approvals?.map(admin => (
            <div className="loan-request-info-popup__body__requester-info__item">
              <div className="loan-request-info-popup__body__requester-info__item__member">
                <div className="flex flex-column">
                  <span>
                    {`${admin?.user?.first_name} ${admin?.user?.last_name}`}
                  </span>
                  <span className="loan-request-info-popup__body__requester-info__item__phone">
                    {capitalize(
                      admin?.user?.roles?.[0]?.name?.replace(
                        /_/g,
                        ' ',
                      ) || '',
                    )}
                  </span>
                </div>
              </div>

              <Label color={getStatusColor(admin.status)}>
                {admin.status === 'accepted'
                  ? 'Approved'
                  : capitalizeEachWords(admin.status)}
              </Label>
            </div>
          ))}
        </div>

        <div className="loan-request-info-popup__body__loan-info">
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Requester:</span>
            <span>{`${request?.user?.first_name} ${request?.user?.last_name}`}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Loan amount:</span>
            <span>{currencyFormat(request?.amount)}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Disbursed amount:</span>
            <span>
              {request.is_modified
                ? currencyFormat(request?.modified_request?.amount)
                : currencyFormat(request?.amount)}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Disbursed date:</span>
            <span>
              {request?.loan_disbursed_date
                ? formatDate(
                    request?.loan_disbursed_date,
                    'DD-MM-YYYY',
                  )
                : '-'}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Repayment Days:</span>
            <span>{request?.repayment_days}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Repayment installments:</span>
            <span>{request?.repayment_installments}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Payment/Installment:</span>
            <span>
              {currencyFormat(
                request?.modified_installment_amount ||
                  request?.repayment_installment_amount,
              )}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Interest:</span>
            <span>
              {request.is_modified
                ? currencyFormat(
                    request?.modified_loans_interest ||
                      request?.modified_request?.loans_interest,
                  )
                : currencyFormat(request?.loans_interest)}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Due Date:</span>
            <span>
              {request?.loans_due_date
                ? formatDate(request?.loans_due_date, 'DD-MM-YYYY')
                : '-'}
            </span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Payed amount:</span>
            <span>{currencyFormat(request?.loans_paid)}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Overdue Loan:</span>
            <span>{currencyFormat(request?.overdue_amount)}</span>
          </div>
          <div className="loan-request-info-popup__body__loan-info__item">
            <span>Outstanding Balance:</span>
            <span>{currencyFormat(request?.loans_balance)}</span>
          </div>
        </div>

        <div className="loan-request-info-popup__body__requester-info">
          <div className="loan-request-info-popup__body__requester-info__title">
            <span>Loan Requested by</span>
          </div>

          <div className="loan-request-info-popup__body__requester-info__item">
            <div className="loan-request-info-popup__body__requester-info__item__member">
              <div className="flex flex-column">
                <span>
                  {request.type === 'individual_micro_loan'
                    ? `${request?.user?.first_name} ${request?.user?.last_name}`
                    : request?.group?.name}
                </span>
                <span className="loan-request-info-popup__body__requester-info__item__phone">
                  {request.type === 'individual_micro_loan' ? (
                    <>
                      <Icon name="phone" />
                      <span>
                        {formatPhone(
                          request?.user?.phone_number ?? '-',
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <span>Group ID: {request?.group?.id}</span>
                    </>
                  )}
                </span>
              </div>
            </div>

            {/* <Label color={getStatusColor('accepted')}>Approved</Label> */}
          </div>

          <div className="loan-request-info-popup__body__requester-info__item">
            <div className="loan-request-info-popup__body__requester-info__item__member">
              <div className="flex flex-column">
                <span>
                  Credit Score
                  <Popup
                    trigger={<Icon name="info circle" color="blue" />}
                    content={
                      <div className="loan-request-info-popup__body__requester-info__popup">
                        <div className="loan-request-info-popup__body__requester-info__popup__title">
                          Credit Score
                        </div>
                        <Divider />
                        <div className="loan-request-info-popup__body__requester-info__popup__body">
                          {creditScoreExplainer.map(
                            ({ range, color, description }) => {
                              return (
                                <div className="loan-request-info-popup__body__requester-info__popup__body__item">
                                  <div
                                    className="loan-request-info-popup__body__requester-info__popup__body__item__color"
                                    style={{
                                      backgroundColor: `${color}`,
                                    }}
                                  />
                                  <div className="loan-request-info-popup__body__requester-info__popup__body__item__data">
                                    <span className="loan-request-info-popup__body__requester-info__popup__body__item__score">
                                      {range}
                                    </span>
                                    <span className="loan-request-info-popup__body__requester-info__item__phone">
                                      {description}
                                    </span>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </div>
                      </div>
                    }
                    on="click"
                    hoverable
                    position="left center"
                  />
                </span>
                <span className="loan-request-info-popup__body__requester-info__item__phone">
                  {new Date().toDateString()}
                </span>
              </div>
            </div>

            <Label
              color={getCreditScoreColor(
                request.type === 'individual_micro_loan'
                  ? request?.user?.loan_score
                  : request.group?.loan_score,
              )}
            >
              {request.type === 'individual_micro_loan'
                ? request?.user?.loan_score
                : request.group?.loan_score}
            </Label>
          </div>

          <div className="loan-request-info-popup__body__requester-info__item">
            <div className="loan-request-info-popup__body__requester-info__item__member">
              <span>Loan Limit Suggestion</span>
            </div>

            <span>
              {currencyFormat(
                request.type === 'individual_micro_loan'
                  ? request?.user?.loan_limit
                  : request.group?.loan_limit,
              )}
            </span>
          </div>

          <div className="loan-request-info-popup__body__requester-info__item">
            <div className="loan-request-info-popup__body__requester-info__item__member">
              <span>
                Risk Rating
                <Popup
                  trigger={<Icon name="info circle" color="blue" />}
                  content={
                    <div className="loan-request-info-popup__body__requester-info__popup">
                      <div className="loan-request-info-popup__body__requester-info__popup__title">
                        Risk Rating
                      </div>
                      <Divider />
                      <div className="loan-request-info-popup__body__requester-info__popup__body">
                        <div className="loan-request-info-popup__body__requester-info__popup__body__item">
                          <span className="loan-request-info-popup__body__requester-info__popup__body__item__score">
                            Low Risk
                          </span>
                        </div>
                        <div className="loan-request-info-popup__body__requester-info__popup__body__item">
                          <span className="loan-request-info-popup__body__requester-info__popup__body__item__score">
                            Moderate Risk
                          </span>
                        </div>
                        <div className="loan-request-info-popup__body__requester-info__popup__body__item">
                          <span className="loan-request-info-popup__body__requester-info__popup__body__item__score">
                            High Risk
                          </span>
                        </div>
                        <div className="loan-request-info-popup__body__requester-info__popup__body__item">
                          <span className="loan-request-info-popup__body__requester-info__popup__body__item__score">
                            Extremely High Risk
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                  on="click"
                  hoverable
                  position="left center"
                />
              </span>
            </div>

            <span>
              {capitalize(
                request.type === 'individual_micro_loan'
                  ? request?.user?.loan_risk_level
                  : request.group?.loan_risk_level,
              )}
            </span>
          </div>
        </div>

        {request.is_modified || (
          <div className="loan-request-info-popup__body__committe-info">
            <div className="loan-request-info-popup__body__committe-info__title">
              <span>Committee approvals</span>
            </div>

            {request?.approvals?.map(committee => (
              <div
                key={`${committee?.id}`}
                className="loan-request-info-popup__body__committe-info__item"
              >
                <div className="loan-request-info-popup__body__committe-info__item__member">
                  <span>{`${committee?.user?.first_name} ${committee?.user?.last_name}`}</span>
                  {committee?.user?.phone_number && (
                    <span className="loan-request-info-popup__body__committe-info__item__phone">
                      <Icon name="phone" />{' '}
                      <span>
                        {formatPhone(committee?.user?.phone_number)}
                      </span>
                    </span>
                  )}
                </div>

                <Label color={getStatusColor(committee.status)}>
                  {committee.status === 'accepted'
                    ? 'Approved'
                    : capitalizeEachWords(committee.status)}
                </Label>
              </div>
            ))}
          </div>
        )}
        <div className="loan-request-info-popup__body__committe-info">
          <div className="loan-request-info-popup__body__committe-info__title">
            <span>Agent advisory comment</span>
          </div>
          <div className="loan-request-info-popup__body__committe-info__item">
            <div className="loan-request-info-popup__body__committe-info__item__member">
              <span>{`${request?.agent?.first_name} ${request?.agent?.last_name}`}</span>
              {request?.agent?.phone_number && (
                <span className="loan-request-info-popup__body__committe-info__item__phone">
                  <Icon name="phone" />{' '}
                  <span>
                    {formatPhone(request?.agent?.phone_number)}
                  </span>
                </span>
              )}
            </div>

            <span
              className={`loan-request-info-popup__${request?.agent_approval_status}`}
            >
              {capitalizeEachWords(request?.agent_approval_status)}
            </span>
          </div>
          {request?.comment && (
            <div className="loan-request-info-popup__body__committe-info__item__member">
              <span>Comment</span>
              <span className="loan-request-info-popup__body__committe-info__item__phone">
                {request?.comment}
              </span>
            </div>
          )}
        </div>

        {request.is_modified && (
          <>
            <div className="loan-request-info-popup__body__committe-info">
              <div className="loan-request-info-popup__body__committe-info__title">
                <span>Amendement details</span>
              </div>
              <div className="loan-request-info-popup__body__loan-info__item">
                <span>Requested amount</span>
                <span>
                  {currencyFormat(
                    request?.modified_amount ||
                      request?.modified_request?.amount,
                  )}
                </span>
              </div>
              <div className="loan-request-info-popup__body__loan-info__item">
                <span>Repayment days</span>
                <span>
                  {request?.modified_repayment_days ||
                    request?.modified_request?.repayment_days}
                </span>
              </div>
              <div className="loan-request-info-popup__body__loan-info__item">
                <span>Repayment installment:</span>
                <span>
                  {request?.modified_installments ||
                    request?.modified_request?.repayment_installments}
                </span>
              </div>
              <div className="loan-request-info-popup__body__loan-info__item">
                <span>Payment/installment:</span>
                <span>
                  {currencyFormat(
                    request?.modified_installment_amount ||
                      request?.modified_request
                        ?.repayment_installment_amount,
                  )}
                </span>
              </div>
            </div>

            <div className="loan-request-info-popup__body__committe-info">
              <div className="loan-request-info-popup__body__committe-info__title">
                <span>Reason for Amendement</span>
              </div>
              <div className="loan-request-info-popup__body__loan-info__item">
                <span>
                  {request?.modified_reason ||
                    request?.modified_request?.reason}
                </span>
              </div>
            </div>

            <div className="loan-request-info-popup__body__committe-info">
              <div className="loan-request-info-popup__body__committe-info__title">
                <span>Amendement approval by committee</span>
              </div>

              {request?.approvals?.map(commettee => (
                <div
                  key={`${commettee?.id}`}
                  className="loan-request-info-popup__body__committe-info__item"
                >
                  <div className="loan-request-info-popup__body__committe-info__item__member">
                    <span>{`${commettee?.user?.first_name} ${commettee?.user?.last_name}`}</span>
                    {commettee?.user?.phone_number && (
                      <span className="loan-request-info-popup__body__committe-info__item__phone">
                        <Icon name="phone" />{' '}
                        <span>
                          {formatPhone(commettee?.user?.phone_number)}
                        </span>
                      </span>
                    )}
                  </div>

                  <Label color={getStatusColor(commettee.status)}>
                    {commettee.status === 'accepted'
                      ? 'Approved'
                      : capitalizeEachWords(commettee.status)}
                  </Label>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

RequestPopupContent.propTypes = {
  request: PropTypes.object.isRequired,
};

export default RequestPopupContent;
