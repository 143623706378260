/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './index.scss';
import { Table, Icon, Dropdown } from 'semantic-ui-react';
import formatNumber from 'helpers/formatNumber';
import capitalize from 'utils/capitalize';
import Thumbnail from 'components/common/Thumbnail';
import getUserRoles from 'helpers/getUserRoles';
import isParentOrganization from 'helpers/isParentOrganization';
import getWallets from 'redux/actions/wallets/getWallets';
import { getStatusColor } from 'containers/Organizations/SingleOrganization/Profiles/SingleProfile/Requests/SingleRequest/RequestPopupContent';

const LoanRequestTable = ({ items }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const isCurrentOrgParent = isParentOrganization();
  const { currentOrgId } = getUserRoles();

  const params = useParams();

  useEffect(() => {
    if (isCurrentOrgParent) {
      getWallets()(dispatch);
    } else {
      getWallets(currentOrgId)(dispatch);
    }
  }, [isCurrentOrgParent]);

  return (
    <>
      <div className="list-loan-requests__items__table">
        <Table basic="very" size="small" textAlign="left">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="">Group</Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Micro-loan type
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Requested amount{' '}
                  <span className="table-data__currency">(RWF)</span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Repayment Days
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Interest on Loan{' '}
                  <span className="table-data__currency">(RWF)</span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Outstanding Loan{' '}
                  <span className="table-data__currency">(RWF)</span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Loan reason
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Expiration Date
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Status
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data" width="1">
                Action
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body
            className={`list-loan-requests__items__table__tbody wallet__transactions--table-body ${true &&
              'lg-tbody'}`}
          >
            {items.map(request => {
              return (
                <Table.Row
                  key={request.id}
                  className="table-row cursor"
                >
                  <Table.Cell
                    className="wallet__transactions--originator"
                    onClick={() => {
                      push(
                        `/organizations/${params?.organizationId}/requests/${request?.id}`,
                      );
                    }}
                  >
                    <Thumbnail name={request?.group?.name} />
                    <div>
                      <span>{request?.group?.name}</span>
                      <div style={{ fontSize: 10 }}>
                        <b>Profile: </b>
                        <span>
                          {request?.group?.loan_profile_monthly?.[0]
                            ?.loan_profile?.name || 'No profile'}
                        </span>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {capitalize(request?.type?.replace(/_/g, ' '))}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.amount)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.repayment_days)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.loans_interest)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.loans_balance)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {capitalize(request.purpose)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {request?.expiration_date
                      ? moment(request.expiration_date).format(
                          'MMM DD, YYYY',
                        )
                      : '-'}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    <div
                      className="status-dot"
                      style={{
                        backgroundColor: getStatusColor(
                          request.status,
                        ),
                      }}
                    />
                    {capitalize(request.status)}
                  </Table.Cell>
                  <Table.Cell className="table-data" width="1">
                    <div className="flex flex-between flex-center-vertical">
                      <Dropdown
                        icon={null}
                        direction="left"
                        trigger={
                          <div className="action-icon">
                            <Icon name="ellipsis vertical" />
                          </div>
                        }
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              push(
                                `/organizations/${params?.organizationId}/requests/${request?.id}`,
                              );
                            }}
                          >
                            View
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

LoanRequestTable.defaultProps = {
  items: [],
};

LoanRequestTable.propTypes = {
  items: PropTypes.array,
};

export default LoanRequestTable;
