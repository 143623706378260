export const walletTypes = ['group', 'individual'];

export const chargeFromWallet = ['group_wallet', 'individual_wallet'];

export const getChargeFrom = ['wallet', 'transaction'];

export const computeOnTransactionTypes = ['credit', 'debit'];

export const computeFromTransactionField = [
  'amount',
  'amount_with_interest',
];

export const chargeOnTransactionTypes = ['credit', 'debit'];

export const chargePerCategory = ['group', 'group_member'];

export const providerTypes = ['Access_bank', 'telco'];

export const groupsPurpose = ['savings', 'savings_and_loans'];

export const transactionTypes = ['credit', 'debit'];

export const purposeTypes = [
  'saving',
  'bulk_saving',
  'loan',
  'social_fund',
  'individual_wallet_top_up',
  'individual_wallet_withdraw',
];

export const chargeTypes = ['fixed', 'percentage'];

export const frequencyTypes = ['instant', 'monthly'];

export const chargeAfterDeactive = [
  { text: 'no', value: false },
  { text: 'yes', value: true },
];

export const billNameTypes = {
  name: 'name',
  wallet_type: 'wallet_type',
  get_charge_from: 'get_charge_from',
  compute_on_transaction_type: 'compute_on_transaction_type',
  compute_from_transaction_field: 'compute_from_transaction_field',
  charge_on_transaction_type: 'charge_on_transaction_type',
  charge_per_category: 'charge_per_category',

  charge_from_wallet: 'charge_from_wallet',
  groups_types: 'groups_types',
  groups_purposes: 'groups_purposes',
  wallet_provider: 'wallet_provider',
  transaction_purposes: 'transaction_purposes',
  amount_minimum: 'amount_minimum',
  amount_maximum: 'amount_maximum',
  charge_type: 'charge_type',
  charge: 'charge',
  charge_frequency: 'charge_frequency',
  effective_date: 'effective_date',
  charge_after_deactivation: 'charge_after_deactivation',
  description: 'description',
  status: 'status',
  en: 'en',
  rw: 'rw',
};

export const billRestrictCategories = [
  { purpose: 'transaction_charge' },
  { purpose: 'subscription_fees' },
  { purpose: 'group_subscription_fees' },
  { purpose: 'group_subscription_savings' },
  { purpose: 'group_subscription_social_funds' },
];

export const billsRequiredFields = [
  'name',
  'description',
  'transaction_purposes',
  'amount_minimum',
  'amount_maximum',
  'charge_type',
  'charge',
  'charge_frequency',
  'charge_from_wallet',
  'compute_on_transaction_type',
  'compute_from_transaction_field',
  'effective_date',
  'notification',
];
