/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import PieChart from 'components/common/charts/PieChart/PieChartWrapper';
import BarChart from 'components/common/charts/BarChart/BarChartWrapper';
import LineChartWrapper from 'components/common/charts/LineChart/LineChartWrapper/LineChartWrapper';
import todaysDate from 'utils/todaysDate';
import capitalize from 'utils/capitalize';

const StatCharts = ({
  overview: {
    enrolment_per_gender = [],
    distribution_per_agent: agentsPerProvince = [],
    members_age_range = [],
    MNO_per_province: MNOPerProvince = [],
    cumulative_savings_vs_cumulative_loans,
    agent_education_levels: agentEducationLevels,
  },
  year,
}) => {
  const colors = ['#8884d8', '#83a6ed', '#8dd1e1'];
  const member_ages =
    members_age_range &&
    members_age_range.map((item, i) => {
      item.fill = colors[i];
      item.name = item.age;
      return item;
    });

  const new_enrolment_per_gender =
    enrolment_per_gender &&
    enrolment_per_gender.map(item => {
      return {
        Female: item.females || item.female,
        Male: item.males || item.male,
        name: (item.name || item.month).substring(0, 3),
      };
    });

  const cleanSavingsVsLoans =
    cumulative_savings_vs_cumulative_loans &&
    cumulative_savings_vs_cumulative_loans.map(item => {
      return {
        ...item,
        name: item.month.substring(0, 3),
      };
    });

  const cleanAgentEducationLevel =
    agentEducationLevels &&
    agentEducationLevels.map(item => ({
      Agents: item.education_level,
      name: capitalize(item.education_level),
      total: Number(item.total),
    }));

  const newAgentPerProvince = agentsPerProvince.map(item => ({
    ...item,
    name: item.province,
  }));

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <BarChart
            stack
            title="Agent Distribution Per Province"
            data={newAgentPerProvince}
            dataKey1="male"
            dataKey2="female"
            dataKey3="groups"
            date={todaysDate(year)}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'MNO Distribution Per Province',
              date: todaysDate(year),
              data: MNOPerProvince,
              cx: 100,
              cy: 300,
              dataKey1: 'MTN',
              dataKey2: 'Airtel',
            }}
          />
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={8}>
          <LineChartWrapper
            title="SGs Enrolment Per Gender"
            dataKey1="Male"
            dataKey2="Female"
            width={440}
            height={300}
            data={new_enrolment_per_gender}
            date={todaysDate(year)}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <PieChart
            title="Agent Level Of Education"
            dataKey="total"
            nameKey="Agents"
            width={300}
            height={330}
            data={cleanAgentEducationLevel}
            date={todaysDate(year)}
          />
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={8}>
          <PieChart
            filled
            title="Members Age Range"
            width={400}
            height={320}
            barSize={10}
            dataKey="total"
            nameKey="age"
            data={member_ages}
            date={todaysDate(year)}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <BarChart
            {...{
              title: 'Cumulative Savings Vs Cumulative Loans',
              date: todaysDate(year),
              data: cleanSavingsVsLoans,
              dataKey1: 'savings',
              dataKey2: 'loans',
            }}
          />
        </Grid.Column>
      </Grid>
    </>
  );
};
StatCharts.defaultProps = {
  overview: {},
  year: '',
};

StatCharts.propTypes = {
  overview: PropTypes.instanceOf(Object).isRequired,
  year: PropTypes.string,
};
export default StatCharts;
