import React from 'react';
import PropTypes from 'prop-types';
import PieChart from 'components/common/charts/PieChart';
import './details.scss';
import labelFormatter from 'helpers/labelFormatter';

const AverageCreditScore = ({ data = [] }) => {
  return (
    <div className="average-credit-score">
      <h4 className="text-center">
        Average Credit Score By Borrowers
      </h4>

      <PieChart
        filled
        showLegend
        title=""
        width={312}
        height={234}
        innerRadius={50}
        outerRadius={110}
        barSize={10}
        dataKey="value"
        nameKey="name"
        data={
          data?.map(el => ({
            name: labelFormatter(el.loan_score_category),
            value: Number(el.percentage),
          })) || []
        }
        date=""
        legendStyle={{
          left: 245,
          top: 50,
        }}
      />
    </div>
  );
};
AverageCreditScore.defaultProps = {};

AverageCreditScore.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
};

export default AverageCreditScore;
