/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { Form, Input, Message, Divider } from 'semantic-ui-react';
import Toolbar from 'components/common/Toolbar';
import './index.scss';
import isValidId from 'helpers/validators/isValidId';
import UserSearch from 'containers/Users/UserSearch';
import { idTypeOptions } from 'utils/dropdownOptions';
import nidaVerification from 'helpers/nidaVerification';
import shouldUseNIDA from 'helpers/shouldUseNIDA';

const AddUserUI = ({
  id,
  isCreating,
  setId,
  setWorkEmail,
  idType,
  setIdType,
  error,
  onSubmit,
  emailError,
  setOccupation,
  phoneError,
  setPhone,
  setFirstName,
  setLastName,
}) => {
  const [userExists, setUserExists] = useState();
  const [user, setUser] = useState({});

  const [nidaData, setNidaData] = useState({});
  const [fetchNidaLoading, setFetchNidaLoading] = useState(false);

  const isDevEnv = process.env.REACT_APP_NODE_ENV === 'development';

  const handlePhoneChange = (e, { value, addition, user = {} }) => {
    setPhone(value);
    setUser(user);

    if (!_.isEmpty(user)) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setId(user.id_number);
      setWorkEmail(user.work_email);
      setOccupation(user.occupation);
    }

    if (value && !addition) {
      return setUserExists(true);
    }

    return setUserExists(false);
  };

  const fetchIdNumber = async nationalId => {
    setFetchNidaLoading(true);

    const res = await nidaVerification('fetch', nationalId);
    setFetchNidaLoading(false);
    setNidaData(res);
  };

  useEffect(() => {
    if (isValidId(id) && shouldUseNIDA(idType || user.id_type)) {
      if (!isDevEnv) fetchIdNumber(id);
    }
  }, [id]);

  return (
    <>
      <Toolbar header={{ title: 'Add User' }} />
      <div className="scroll-wrapper">
        <Form
          onSubmit={() => onSubmit({ userExists, user })}
          loading={isCreating}
          data-cy="adminCreationForm"
        >
          <UserSearch name="phone" handleChange={handlePhoneChange} />

          <>
            <Form.Group widths="equal">
              <Form.Dropdown
                placeholder="Select"
                fluid
                label="ID Type"
                selection
                defaultValue={user.id_type}
                options={idTypeOptions}
                name="id_type"
                onChange={(_, { value }) => {
                  setIdType(value);
                }}
                disabled={userExists}
              />
              <Form.Field
                id="form-input"
                control={Input}
                required
                label="ID Number"
                placeholder="ID Number..."
                name="idNumber"
                defaultValue={user.id_number}
                disabled={userExists}
                loading={fetchNidaLoading}
                error={
                  id && !isValidId(id)
                    ? { content: 'Wrong ID Number' }
                    : nidaData.fail
                    ? { content: 'Invalid national ID' }
                    : false
                }
                icon={id && isValidId(id) ? 'checkmark' : ''}
                onChange={e => {
                  setId(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Input
              required
              label="Names"
              name="first_name"
              placeholder="Names"
              value={
                isDevEnv && !userExists
                  ? user.first_name
                  : nidaData.fail === false
                  ? `${nidaData.foreName ||
                      user.first_name ||
                      ''}  ${nidaData.surnames ||
                      user.last_name ||
                      ''}`
                  : userExists
                  ? `${user.first_name || ''}  ${user.last_name ||
                      ''}`
                  : ''
              }
              defaultValue={
                nidaData.fail === false
                  ? `${nidaData.foreName ||
                      user.first_name ||
                      ''}  ${nidaData.surnames ||
                      user.last_name ||
                      ''}`
                  : userExists
                  ? `${user.first_name || ''}  ${user.last_name ||
                      ''}`
                  : ''
              }
              disabled={
                userExists ||
                (shouldUseNIDA(idType || user.id_type) && !isDevEnv)
              }
              onChange={(e, { value }) => {
                setUser({
                  ...user,
                  first_name: value,
                });
              }}
            />

            <Form.Group widths="equal" name="phone">
              <Form.Field
                id="form-input-work_email"
                control={Input}
                label="Work Email"
                name="workEmail"
                defaultValue={user.work_email}
                type="email"
                required
                error={
                  emailError !== '' && {
                    content: 'Please enter a valid email',
                    pointing: 'above',
                  }
                }
                placeholder="Work Email..."
                onChange={e => {
                  if (e.target.value) {
                    setWorkEmail(e.target.value);
                  }
                }}
              />
              <Form.Field
                id="form-input-occupation"
                required
                name="occupation"
                defaultValue={user.occupation}
                control={Input}
                label="occupation"
                placeholder="Occupation..."
                onChange={e => {
                  setOccupation(e.target.value);
                }}
              />
            </Form.Group>
            <Message
              error
              data-cy="phoneErrorOutput"
              visible={phoneError !== ''}
              content={!phoneError !== '' ? phoneError : null}
            />
            <Message
              error
              name="errorOutput"
              visible={error.errors || error.message !== undefined}
              content={
                error.errors
                  ? error.errors[0].message.replace(/"/g, '')
                  : error.message
              }
            />
            <Divider />
          </>
          <Form.Button
            primary
            name="addUserButton"
            floated="right"
            content={isCreating ? 'Please wait' : 'Add User'}
            labelPosition="right"
            icon="check"
            disabled={
              // disabled when loading or when we get a failing response from nida.
              isCreating || !idType || (!userExists && nidaData.fail)
            }
          />
        </Form>
      </div>
    </>
  );
};

AddUserUI.defaultProps = {
  idType: null,
};

AddUserUI.propTypes = {
  id: PropTypes.string.isRequired,
  isCreating: PropTypes.bool.isRequired,
  setId: PropTypes.func.isRequired,
  idType: PropTypes.string,
  setIdType: PropTypes.func.isRequired,
  setWorkEmail: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
  setOccupation: PropTypes.func.isRequired,
  phoneError: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      status: PropTypes.number,
      message: PropTypes.string,
    }),
    PropTypes.shape({
      status: PropTypes.number,
      message: PropTypes.string,
      errors: PropTypes.array,
    }),
  ]).isRequired,
};
export default AddUserUI;
