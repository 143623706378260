import React, { useState } from 'react';
import { Accordion, Checkbox, Icon } from 'semantic-ui-react';
import './nestedAccordionToggle.scss';
import PropTypes from 'prop-types';
import { capitalizeEachWords } from 'utils/capitalize';
import AccordionToggle from '..';

const NestedAccordionToggle = ({
  data,
  toggleBulk,
  handleChange,
  disabled,
  linkTo,
}) => {
  const [isActive, setActive] = useState(false);
  const roleContexts = data?.subItems?.map(item => item.context);

  const mainItems = data?.subItems
    ?.filter(item => item.context === 'org_admin')
    ?.map(item => ({
      roles: roleContexts,
      ...item,
    }));
  const subItems = data?.subItems
    ?.filter(item => item.context !== 'org_admin')
    .map(item => ({
      context: item?.context,
      status: item?.status,
      subItems: item?.subItems,
    }));

  const subStatus = subItems?.every(item => item.subItems === false);

  const disableItem = data?.subItems
    ?.map(item => {
      if (item?.subItems?.length === 0) return false;
      return item?.subItems;
    })
    ?.every(value => value === false);

  return (
    <div className="nested-accordion-toggle">
      <Accordion>
        <div className="accordion-toggle__item">
          <Accordion.Title
            active={isActive}
            onClick={() => setActive(!isActive)}
          >
            <div className="accordion-toggle__main">
              <div className="left">
                <Icon name="dropdown" />
                {capitalizeEachWords(data?.title)}
              </div>
              <div className="right">
                <Checkbox
                  toggle
                  checked={
                    !subStatus ? data?.status : mainItems[0]?.status
                  }
                  disabled={disableItem || disabled}
                  onChange={(_, { checked }) =>
                    toggleBulk(
                      checked,
                      mainItems,
                      subItems,
                      'nestedToggle',
                    )
                  }
                />
              </div>
            </div>
          </Accordion.Title>
          <Accordion.Content
            className="nested-accordion-toggle__content"
            active={isActive}
          >
            {data?.subItems?.length > 0 ? (
              data?.subItems?.map(item => (
                <AccordionToggle
                  data={item}
                  handleChange={handleChange}
                  disabled={disabled}
                  linkTo={linkTo}
                />
              ))
            ) : (
              <Icon name="info circle" />
            )}
          </Accordion.Content>
        </div>
      </Accordion>
    </div>
  );
};

NestedAccordionToggle.defaultProps = {
  disabled: true,
  linkTo: '',
};

NestedAccordionToggle.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    subItems: PropTypes.array.isRequired,
  }).isRequired,
  toggleBulk: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  linkTo: PropTypes.string,
};

export default NestedAccordionToggle;
