/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './index.scss';
import { Form, Button, Select, TextArea } from 'semantic-ui-react';
import SimpleModal from 'components/common/modals/SimpleModal';
import PINInput from 'components/common/PINInput';
import telcoOptions from 'constants/options/telcoOptions';
import { toDropDownOptions } from 'utils/dropdownOptions';
import currencyFormat from 'utils/currencyFormat';
import amendLoanRequests from 'redux/actions/loanRequests/amendLoanRequests';
import getCurrentOrg from 'helpers/getCurrentOrg';
import allFieldsRequired from 'helpers/validators/validateFields';
import isFieldRequired from 'helpers/validators/isFieldRequired';
import validateFieldLength from 'helpers/validators/validateFieldLength';

const LoanAmendModal = ({
  open,
  setOpen,
  request,
  onSuccess,
  wallets,
  role,
}) => {
  const { loading } = useSelector(
    ({ loanRequests: { amendLoanRequest } }) => amendLoanRequest,
  );
  const currentUserOrganization = getCurrentOrg();

  const [form, setForm] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setForm({
      ...form,
      amount: request?.amount,
      repayment_days: request?.repayment_days,
      repayment_installments: request?.repayment_installments,
      loans_interest: request?.loans_interest,
      installment_amount: request?.installment_amount,
      repayment_installment_amount:
        request?.repayment_installment_amount,
    });
  }, [request]);

  const getLoansInterest = () => {
    if (form.amount) {
      return Number(
        (form.amount * request.interest_rate) / 100,
      ).toFixed(2);
    }
    return null;
  };

  const getRepaymentInstallmentAmount = () => {
    if (form.amount && form.repayment_installments) {
      const loansInterest =
        (form.amount * request.interest_rate) / 100;

      return Number(
        (Number(form.amount) + loansInterest) /
          form.repayment_installments,
      ).toFixed(2);
    }

    return null;
  };

  useEffect(() => {
    if (form.amount && form.repayment_installments) {
      setForm({
        ...form,
        loans_interest: getLoansInterest(),
        repayment_installment_amount: getRepaymentInstallmentAmount(),
      });
    }
  }, [form.amount, form.repayment_installments]);

  const handleSubmit = () => {
    const isRequired = allFieldsRequired(
      [
        'password',
        'channel',
        'amount',
        'comment',
        'repayment_days',
        'repayment_installments',
      ],
      form,
    );

    if (isRequired) {
      setShowErrors(false);

      return amendLoanRequests(
        currentUserOrganization.id,
        request?.id,
        {
          password: form.password,
          channel: form.channel,
          comment: form.comment,
          amount: form.amount,
          repayment_days: form.repayment_days,
          repayment_installments: form.repayment_installments,
          loans_interest: form.loans_interest,
          installment_amount: form.repayment_installment_amount,
          admin: role,
        },
      )(dispatch, () => {
        onSuccess?.();
        setOpen(null);
      });
    }
    return setShowErrors(true);
  };

  const handleFormChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const selectedChannelWallet = wallets?.find(
    wallet => wallet.provider_name === form.channel,
  );

  return (
    <SimpleModal
      open={open}
      title={`${request?.group?.name} loan request`}
      onClose={() => setOpen(null)}
    >
      <div className="padding confirm-loan-amend">
        <Form onSubmit={handleSubmit} loading={loading}>
          <Form.Input
            name="amount"
            fluid
            type="number"
            label="Loan amount (Rwf)"
            onChange={handleFormChange}
            defaultValue={request?.amount}
            error={isFieldRequired(
              showErrors,
              !form?.amount,
              'Loan amount',
            )}
          />
          <Form.Input
            name="repayment_days"
            fluid
            type="number"
            label="Repayment days"
            onChange={handleFormChange}
            defaultValue={request?.repayment_days}
            error={isFieldRequired(
              showErrors,
              !form?.repayment_days,
              'Repayment days',
            )}
          />
          <Form.Input
            name="repayment_installments"
            fluid
            type="number"
            label="Repayment installments"
            onChange={handleFormChange}
            defaultValue={request?.repayment_installments}
            error={isFieldRequired(
              showErrors,
              !form?.repayment_installments,
              'Repayment installments',
            )}
          />

          <div className="confirm-loan-amend__interests">
            <div className=" flex flex-between">
              <span className="confirm-loan-amend__interests__label">
                Interest on loan
              </span>
              <span className="confirm-loan-amend__interests__value">
                {currencyFormat(form?.loans_interest)}
              </span>
            </div>
            <div className=" flex flex-between">
              <span className="confirm-loan-amend__interests__label">
                Payment/Installment
              </span>
              <span className="confirm-loan-amend__interests__value">
                {currencyFormat(form?.repayment_installment_amount)}
              </span>
            </div>
          </div>

          <span>Reason</span>
          <TextArea
            placeholder="Reason for modification"
            className={`confirm-loan-amend__reason-input ${
              isFieldRequired(showErrors, !form?.comment, 'Reason') ||
              validateFieldLength(
                showErrors,
                form?.comment,
                'Reason',
                10,
              )
                ? 'confirm-loan-amend__reason-error-border'
                : ''
            }`}
            name="comment"
            onChange={handleFormChange}
          />

          <div className=" flex flex-between confirm-loan-amend__reason-error">
            {isFieldRequired(showErrors, !form?.comment, 'Reason') ||
              validateFieldLength(
                showErrors,
                form?.comment,
                'Reason',
                10,
              )}
          </div>

          <Form.Field
            name="channel"
            control={Select}
            fluid
            options={toDropDownOptions(telcoOptions, true)}
            className="confirm-loan-amend__select-input"
            label="Select channel"
            placeholder="Select"
            onChange={handleFormChange}
            error={isFieldRequired(
              showErrors,
              !form?.channel,
              'Channel',
            )}
          />
          {selectedChannelWallet && (
            <div className=" flex flex-between confirm-loan-amend__channel-balance">
              <span className="confirm-loan-amend__channel-balance__label">
                Save Rwanda Float
              </span>
              <span className="confirm-loan-amend__channel-balance__value">
                {currencyFormat(selectedChannelWallet?.float_balance)}
              </span>
            </div>
          )}

          <span>PIN</span>

          <PINInput
            fluid
            size="tiny"
            label="PIN"
            name="password"
            className="confirm-loan-amend__pin-input"
            onInputChange={handleFormChange}
            error={{
              message: isFieldRequired(
                showErrors,
                !form?.password,
                'PIN Number',
              ),
            }}
          />

          <Button
            primary
            fluid
            size="medium"
            type="submit"
            content="Confirm"
          />
        </Form>
      </div>
    </SimpleModal>
  );
};

LoanAmendModal.defaultProps = {
  request: {},
  onSuccess: () => null,
  wallets: [],
};

LoanAmendModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  open: PropTypes.bool.isRequired,
  request: PropTypes.object,
  wallets: PropTypes.arrayOf(PropTypes.object),
  role: PropTypes.string.isRequired,
};

export default LoanAmendModal;
