import React from 'react';
import PropTypes from 'prop-types';
import Thumbnail from 'components/common/Thumbnail';
import './index.scss';
import formatPhone from 'helpers/formatPhone';

const FspDetails = ({ data, singleOrgsData }) => {
  const { organization } = singleOrgsData;

  return (
    <div className="fsp-details">
      <div className="info-wrapper">
        <div className="header">
          <Thumbnail
            name={organization?.name}
            style={{ width: '50px', height: '50px' }}
          />
          <div className="name">{organization?.name}</div>
        </div>

        <div className="fsp-org-details">
          <div className="org-details">
            <div className="org-detail">
              <div>Total Projects</div>
              <div className="align-right">
                {singleOrgsData?.projects_count}
              </div>
            </div>
            <div className="org-detail">
              <div>Total Profiles</div>
              <div className="align-right">
                {data?.profiles_count}
              </div>
            </div>
            <div className="org-detail">
              <div>Total Partners</div>
              <div className="align-right">
                {singleOrgsData?.partners_count}
              </div>
            </div>
          </div>

          <div className="org-info">
            <div className="org-info__type">{organization?.type}</div>
            <div className="org-info__contact">
              <div>{organization?.email}</div>
              <div>{formatPhone(organization?.phone_number)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
FspDetails.defaultProps = {
  data: {},
  singleOrgsData: {},
};

FspDetails.propTypes = {
  data: PropTypes.objectOf(Object),
  singleOrgsData: PropTypes.objectOf(Object),
};

export default FspDetails;
