/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button } from 'semantic-ui-react';
import Loader from 'components/common/PageLoader';
import Toolbar from 'components/common/Toolbar';
import OrganizationGenders from 'components/Organizations/SingleOrganization/Overview/Genders';
import fspOrganizationOverview from 'redux/actions/organizations/fspOrganizationOverview';
import Message from 'components/common/Message';
import getCurrentOrgId from 'helpers/getCurrentOrgId';
import AverageCreditScore from 'containers/Organizations/SingleOrganization/Profiles/SingleProfile/Overview/AverageCreditScore';
import OrganizationLineCharts from './LineCharts';
import StatCharts from './StatCharts';
import FspDetails from './FspDetails';

const FspOverviews = () => {
  const { data, loading, error } = useSelector(
    ({ organizations: { fspOrganizationOverview } }) =>
      fspOrganizationOverview,
  );
  const { data: singleOrgsData } = useSelector(
    ({ organizations: { getSingleOrganization } }) =>
      getSingleOrganization,
  );

  const currentOrgId = getCurrentOrgId();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentOrgId) {
      fspOrganizationOverview(currentOrgId)(dispatch);
    }
  }, [dispatch, currentOrgId]);

  return (
    <>
      <Toolbar
        header={{
          title: 'Overview',
          rightComponent: (
            <Button
              as={NavLink}
              to="/requests"
              activeClassName="disabled"
              exact
              primary
              content="Requests"
            />
          ),
        }}
      />
      <div className="group-overview scroll-wrapper">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message color="red" message={error.message} />
        ) : (
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column width="8">
                <OrganizationLineCharts data={data} />
              </Grid.Column>
              <Grid.Column width="5" rows={2} gap={2}>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <FspDetails
                      data={data}
                      singleOrgsData={singleOrgsData}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Grid.Column>
                      <AverageCreditScore
                        data={
                          singleOrgsData?.average_credit_score ?? []
                        }
                      />
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width="3">
                <OrganizationGenders data={data} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="1">
              <Grid.Column width="16">
                <StatCharts data={data} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    </>
  );
};

export default FspOverviews;
